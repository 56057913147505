/* global site */

// Import site modules
// import ('./site/cookie-policy.js');
import ('./site/contact-form.js');
import ('./site/scroll-to.js');
import ('./site/sliders.js');
import ('./site/ajax-projets.js');
import ('./site/mobile-menu.js');
import ('./site/home-animation.js');
import ('./site/ajax-forms.js');
import ('./site/hoffman-life-hero');
import ('./site/hoffman-life-gallery');
import ('./site/hoffman-life-advantages');
import ('./site/hoffman-life-slider');

window.site = (window.site || {});

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = (function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  let inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  const config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {},
  };

  /**
   * Initializes the class.
   * @public
   */
  const init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = (options || {});

    if (options.env) { config.env = options.env; }
    if (options.csrf) { config.csrf = options.csrf; }
    if (options.csrfName) { config.csrfName = options.csrfName; }
    if (options.locale) { config.locale = options.locale; }
    if (options.device) { config.device = options.device; }
    if (options.preview) { config.preview = options.preview; }
    if (options.general) { config.general = options.general; }

    // Global
    // Detect css-grid un-supported
    const el = document.createElement("div")
    const supportsGrid = "string" == typeof el.style.grid;

    if(!supportsGrid) {
      const body = document.getElementsByTagName("body")[0].classList.add('is-ie-11');
    } else {
      // IE 11 || Old Browser - Ignore zone
      console.table(options);

      // Register ServiceWorker
      if ('serviceWorker' in navigator) {
        console.log('Registering ServiceWorker...');
        window.addEventListener('load', function() {
            navigator.serviceWorker.register('/sw.js', {
                scope: "/"
            }).then(function(registration) {
                // Registration was successful
                console.log('ServiceWorker registration successful with scope: ', registration.scope);
                // Trim the caches on load
                navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage({
                    command: "trimCaches"
                });
           }).catch(function(err) {
                // registration failed :(
                console.log('ServiceWorker registration failed: ', err);
            });
        });
      }
    }

    // Test Footer
    // const $footer = document.querySelector('.footer h2');
    // $footer.innerHTML += ' - Happy Coding!';

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  const getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init,
    config: getConfig,
  };
}());
